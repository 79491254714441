<template>
<div class="main">
  <div class="component-content">

    <TopMenu class="mb-12p">

      <template v-slot:page-name>新增单位</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>
    </TopMenu>

    <div class="content bg-25 p-2 h-100">
      <div class="flex-list mb-2">
        <div style="width:50%;">
          <label class="font-12">单位全称</label>
          <input type="text" class="form-control" placeholder="单位全称" v-model="datas.name">
        </div>
        <div style="width:50%;">
          <label class="font-12">单位简称</label>
          <input type="text" class="form-control" placeholder="单位简称" v-model="datas.nickname">
        </div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'

export default {
  name: 'CustomerEdit',

  data() {
    return {
      loading: false,
      datas: {
        id: null,
        name: null,
        nickname: null,
      },
      allowAttack: false,
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    if (this.$route.params.id) {
      this.updateData()
      console.log('修改')
    }
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
  },

  methods: {

    //更新订单
    updateData() {
      this.$axios.post('/api/customer/get', {
        id: this.$route.params.id
      },{
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.datas = res.data
            console.log(res)
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    destroy() {
      this.$router.push({name: 'CustomerList'})
    },

    save() {
      let _this = this
      this.allowAttack = true

      try {

        if (this.datas==null) throw '请填写客户全称或简称'

        _this.$axios.post('/api/customer/save',
          {
            customer: _this.datas
          },
          {
            headers: {
              token: JSON.parse(sessionStorage.Authorization).token
            }
          }
        )
        .then(res => {
          _this.allowAttack = false

          if (res.status == 200) {
            console.log(res.data)
            if (res.data.success) {
              this.datas = res.data.data
              this.$toast.success(res.data.message)
            } else {
              this.$toast.error(res.data.message)
            }
            
          }
        })
        .catch(error => {
          _this.allowAttack = false
          console.error('error:', error)
        })
      } catch(error) {
        console.error(error)
        _this.allowAttack = false
        this.$toast.error(error)
      }
    }

  },

}
</script>

<style scoped>
  
.flex-list-header .delete {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flex-list-header:hover .delete {
  display: block;
}
.btn-create {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding: 0;
  flex-shrink: 0;
  background-color: #004bff;
}

.vc-container.vc-is-dark {
  color: #fff;
  background-color: #383838;
  border: none;
}
  
</style>
