<template>
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <div class="component-page-btn d-inline-block vertical-align-middle mr-1">
        <slot name="button">
          <!-- <router-link to="/">
            <div class="btn btn-bg-none btn-close">
              <img class="icon-16" src="/static/images/icon/close.svg">
            </div>
          </router-link> -->
        </slot>
      </div>
      <h3 class="m-0 d-inline-block">
        <slot name="page-name"></slot>
      </h3>
    </div>

    <div class="d-flex">
      <slot name="menu-block3"></slot>
      <slot name="menu-block2"></slot>
      <slot name="menu-block1"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopMenu',

  props: {
    pageName: String
  },

  methods: {

    createNew() {
      this.$parent.createNew();
    }

  }
  
}
</script>

<style>

</style>
